<template>
    <div style="background-image: url(/img/traktor.webp)" class="h-screen w-screen bg-cover bg-center">
        <div class="absolute top-[12vh] left-[5vw]">
        </div>
        <div class="absolute bottom-[5vh] left-[5vw] drop-shadow-2xl text-white max-w-[1000px]">
            <ElementsIcon icon="product" size="xl" class="text-white mb-3" />
            <h1 class="text-[2.4rem] md:text-[3rem] font-bold">
                Uups, <br />
                da erwischen Sie echtregional<br />
                im falschen Moment.
            </h1>
            <p>
                Entweder weil das System planmässig nach Ankündigung gewartet wird, oder weil es technische Probleme gibt.<br />
                Wenn Sie Fragen haben, kontaktieren Sie uns bitte unter:
                <br />
                <a href="tel:052 396 50 90">052 396 50 90</a> oder <a href="mailto:info@zuerioberland.ch">info@zuerioberland.ch</a>
                <br />
                Wir sind von Montag bis Freitag von 8–12 und 13.30–17 Uhr erreichbar.
            </p>
        </div>
    </div>
</template>
<script setup>
</script>